import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class Testimonials extends Component {
  render() {
    return (
        <>
      <div className="services-card">
        <span> ................. </span>
        <br/><br/>
        <h1> Témoignages de nos utilisateurs </h1>
        <span> ................. </span>
      </div>
      <br/>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">  
            <h3>Aïcha Djité</h3>
            <h4>Utilisatrice inscrite</h4>
            <p>
              Je suis vraiment satisfaite et j’espère pouvoir bénéficier de vos
              formations. Merci!
            </p>
          </div>
        </div>

        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Thierno Diaw</h3>
            <h4>Utilisateur inscrit</h4>
            <p>
              C'est bien génial c'est une bonne idée de faire ces platforme pour
              nous débutant d'apprendre comment faire des application et des
              site web.
            </p>
          </div>
        </div>

        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Omar Diop</h3>
            <h4>Utilisateur inscrit</h4>
            <p>Honneur d'intégrer votre plate-forme</p>
          </div>
        </div>
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Papa Dieng</h3>
            <h4>Utilisateur inscrit</h4>
            <p>Félicitations pour cette belle initiative</p>
          </div>
        </div>
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Amadou Ndao</h3>
            <h4>Utilisateur inscrit</h4>
            <p>J'ai bien aimé vôtre initiative</p>
          </div>
        </div>
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Jules Sankaré</h3>
            <h4>Utilisateur inscrit</h4>
            <p>J'apprécie beaucoup cette formation</p>
          </div>
        </div>
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Cheikh Maremou Ndiaye</h3>
            <h4>Utilisateur inscrit</h4>
            <p>Je pense améliorer mon niveau</p>
          </div>
        </div>
        <div>
          <img
            width="96"
            height="96"
            src="../images/icons/user.png"
            alt="gender-neutral-user"
          />
          <div className="myCarousel">
            <h3>Oumy khairy</h3>
            <h4>Utilisatrice inscrite</h4>
            <p>Je vous déclare les meilleurs</p>
          </div>
        </div>
      </Carousel>
        </>
    );
  }
}
