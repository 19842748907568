import React from "react";
import {
  Card,
  ListGroup,
  CardBody,
  Accordion,
  Button,
  Container,
  Col,
  Row,
} from "react-bootstrap";
export const CourseCard = () => {
  return (
    <>
      <div className="courses-card">
        <span> ................. </span>
        <h1> Nos formations </h1>
        <span> ................. </span>
      </div>
      <Container id="card-container">
        <Row>
          <Col style={{ margin: "10px", boxShadow: "2px 2px 2px #f1faee" }}>
            <img variant="top" src="../images/64363.jpg" />
            <Card.Body>
              <Card.Title>Développement web Full stack</Card.Title>
              <Card.Text>
                React js / Javascript / Bootstrap / Mysql / Node js
              </Card.Text>
            </Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Accordion className="cordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="cordion-header">
                      Informations{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Deviens un développeur full stack complet en maîtrisant le
                      front-end et le back-end avec les technologies les plus
                      demandées. 🎯 Ce que tu apprendras : Front-end : Création
                      d’interfaces utilisateurs dynamiques avec HTML, CSS,
                      JavaScript et React.js Back-end : Développement
                      d’applications robustes avec Node.js et gestion de bases
                      de données avec MySQL API REST, sécurité et optimisation
                      des performances
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListGroup.Item>
            </ListGroup>
            <CardBody>
              <Card.Link>
                <Button
                  id="link-btn"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_API_URL_CHECKOUT}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  Acheter
                </Button>
                <Button id="link-btn">20.000 FR</Button>
              </Card.Link>
            </CardBody>
          </Col>
          <Col style={{ margin: "10px", boxShadow: "2px 2px 2px  #f1faee" }}>
            <img variant="top" src="../images/123931.jpg" />
            <Card.Body>
              <Card.Title>Formation programmation avancée</Card.Title>
              <Card.Text>Python / Javascript </Card.Text>
            </Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Accordion className="cordion">
                  <Accordion.Item className="cordion" eventKey="0">
                    <Accordion.Header className="cordion-header">
                      Informations{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Construire des applications web dynamiques et modernes.
                      Maîtriser l'automatisation des tâches et le traitement de
                      données. Intégrer des fonctionnalités interactives sur des
                      sites web. Préparer des bases solides pour développer des
                      projets avancés comme des microservices ou des
                      applications complexes.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListGroup.Item>
            </ListGroup>
            <CardBody>
              <Card.Link>
                <Button
                  id="link-btn"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_API_URL_CHECKOUT}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  Acheter
                </Button>
                <Button id="link-btn">15.000 FR</Button>
              </Card.Link>
            </CardBody>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col style={{ margin: "10px", boxShadow: "2px  2px 2px #f1faee" }}>
            <img variant="top" src="../images/18084.jpg" />
            <Card.Body>
              <Card.Title>Développement web Backend</Card.Title>
              <Card.Text>Javascript / Mysql / Node js</Card.Text>
            </Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Accordion className="cordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="cordion-header">
                      Informations{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Apprends à gérer des bases de données avec MySQL et à
                      développer des applications back-end robustes avec
                      Node.js. Cette formation te permettra de maîtriser
                      l'interaction entre serveur et base de données. Ce que tu
                      apprendras : Créer et gérer des bases de données
                      relationnelles avec MySQL Développer des applications
                      performantes avec Node.js Connecter et manipuler les
                      données de manière efficace.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListGroup.Item>
            </ListGroup>
            <CardBody>
              <Card.Link>
                <Button
                  id="link-btn"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_API_URL_CHECKOUT}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  Acheter
                </Button>
                <Button id="link-btn">15.000 FR</Button>
              </Card.Link>
            </CardBody>
          </Col>
          <Col style={{ margin: "10px", boxShadow: "2px  2px 2px #f1faee" }}>
            <img variant="top" src="../images/19858.jpg" />
            <Card.Body>
              <Card.Title>Développement web Frontend</Card.Title>
              <Card.Text>
                Html , Css , Javascript , React js , Bootstrap{" "}
              </Card.Text>
            </Card.Body>
            <ListGroup>
              <ListGroup.Item>
                <Accordion className="cordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="cordion-header">
                      Informations{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      Maîtrise les bases du développement web et apprends à
                      créer des interfaces utilisateur modernes avec HTML, CSS
                      et React.js ! Ce que tu apprendras : Structurer des pages
                      web avec HTML Styliser et rendre tes sites interactifs
                      avec CSS Créer des interfaces dynamiques et performantes
                      avec React.js Formation en ligne flexible, avec projets
                      pratiques
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListGroup.Item>
            </ListGroup>
            <CardBody>
              <Card.Link>
                <Button
                  id="link-btn"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_API_URL_CHECKOUT}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  Acheter
                </Button>
                <Button id="link-btn">12.000 FR</Button>
              </Card.Link>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </>
  );
};
