import { BrowserRouter as Router , Routes , Route } from "react-router-dom";
import { HomeGroupe } from "../components/home-group";


export const EndPoint =  ()=>{
    return(
        <Router>
            <Routes>
                <Route  path="*" element={  < HomeGroupe />  } />
            </Routes>
        </Router>
    )
}