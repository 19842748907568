import React from "react";
import { ContainerCarousel } from "./home-container";
import { NavBar } from "./navbar";
import { CardContent } from "./card";
import { ServicesCard } from "./services-card";
import { CourseCard } from "./course-card";
import { FeedBackForm } from "./feedback-form";
import { FooterLinks } from "./footer";
import Testimonials from "./testimonial";

export const HomeGroupe  = ()=>{
    return(
        <>
            <NavBar />
            <ContainerCarousel/>
            <CardContent />
            <ServicesCard />
            <CourseCard />
            <br/><br/>
            <Testimonials />
            <FeedBackForm />
            <FooterLinks/>
        </>
    )
}
