import React from "react";
import { Button } from "react-bootstrap";
import { Stack, Navbar, Nav, Container } from "react-bootstrap";
export const NavBar = () => {

  return (
    <>
      {/*Header */}
      <Stack direction="horizontal" gap={3} className="width">
        <div className="p-2" id="title">
          <Navbar.Brand>
            <h1
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL_HOME}`)
              }
              style={{ cursor: "pointer" }}
            >
              {" "}
              Informatiques<span className="span"> en wolof</span>
            </h1>
          </Navbar.Brand>
        </div>
        <div className="p-2 ms-auto">
          <Button
            variant="primary"
            className="mail-btn"
            onClick={() =>
              (window.location.href = "mailto:admin@sen-teranga.com")
            }
          >
            <img src="../images/icons/email.png" alt="secured-letter" />
          </Button>
        </div>
        <div className="p-2">
          <Button
            variant="primary"
            className="btn-wth"
            onClick={() =>
              (window.location.href = "https://wa.me/221704291214")
            }
          >
            <img src="../images/icons/whatsapp.png" alt="whatsapp" />
          </Button>
        </div>
      </Stack>
      {/*Menu de navigation */}
      <Navbar
        expand="lg"
        className="navbar full-width-navbar"
        variant="light"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand className="link">Menu</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-collapse-id" />
          <Navbar.Collapse id="navbar-collapse-id">
            <Nav activeKey="/link" className="me-auto">
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href = process.env.REACT_APP_API_URL_HOME)
                }
                style={{ cursor: "pointer" }}
              >
                Accueil
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL_PAID_COURSE)
                }
                style={{ cursor: "pointer" }}
              >
                Formations
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL_SERVICES}`)
                }
                style={{ cursor: "pointer" }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL_COMMUNAUTY}`)
                }
                style={{ cursor: "pointer" }}
              >
                Communauté
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL_MY_ACCOUNT}`)
                }
                style={{ cursor: "pointer" }}
              >
                Mon compte
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL_FREE_COURSE)
                }
                style={{ cursor: "pointer" }}
              >
                Cours gratuits
              </Nav.Link>
              <Nav.Link
                eventKey="link-event-key"
                className="link"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL_ABOUT_US)
                }
                style={{ cursor: "pointer" }}
              >
                Propos
              </Nav.Link>
              <Nav.Link eventKey="link-event-key" className="link">
                Connexion
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
