import axios from "axios";

export const submitFormContact  = (fullname,email,number,message) => {
    axios({
     method:'post',
     url:`${process.env.REACT_APP_URL_BACK}`,
     withCredentials:true,
     withXSRFToken:true,
     data:
     {
         fullname:fullname,
         email:email,
         number:number,
         message:message
     }
    })
    .then((res)=>{
        alert('Message envoyé avec succès !')
    })
    .catch((err)=>{
        alert('Une erreur est survenue !')
    })
 }
 
 