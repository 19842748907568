import React from "react";
import { Carousel } from "react-bootstrap";
const h1 =   `Notre Mission`
const text1 = ` Accompagner la communauté africaine dans son développement
                        professionnel et entrepreneurial en mettant à disposition des
                        outils, des formations, et un réseau adapté à ses réalités.`
const h2 = `Un réseau pour tous les professionnels`
const text2 = ` Connectez-vous avec des talents locaux et internationaux,
                        développez votre réseau et trouvez des opportunités
                        professionnelles adaptées à votre secteur.`
export const ContainerCarousel = ({title=h1   , otitle=h2,   text=text1,   otext=text2}) => {
  return (
    <>
      <Carousel>
        <Carousel.Item className="carousel-1">
          <div className="carousel-child-1">
            <h1>{title}</h1>
            <article>
                {text}
            </article>
          </div>
        </Carousel.Item>
        <Carousel.Item className="carousel-2">
          <div className="carousel-child-2">
            <h1> {otitle} </h1>
            <article>
                {otext}
            </article>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};
