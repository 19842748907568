import React from "react";
import { Card, CardGroup } from "react-bootstrap";
export const ServicesCard = () => {
  return (
    <>
      <div className="services-card">
        <span> ................. </span>
        <h1> Ce nous vous proposons </h1>
        <span> ................. </span>
      </div>
      <CardGroup>
        <Card>
          <Card.Img variant="top" src="../images/Developer activity-bro.svg" />
          <Card.Body>
            <Card.Title>Formation Développement Web et logiciel</Card.Title>
            <Card.Text>
              Accédez à des formations pratiques en ligne, pécialement conçues
              pour vous aider à maîtriser des technologies. comme Node.js et le
              développement back-end quel que soit votre niveau..
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted" onClick={()=>window.location.href=`${process.env.REACT_APP_API_URL_PAID_COURSE}`} style={{cursor:'pointer'}}>Découvrir</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="../images/Website Creator-bro.svg" />
          <Card.Body>
            <Card.Title>Création Site web et logiciel</Card.Title>
            <Card.Text>
              Nous créons des sites web adaptés à vos besoins quelle que soit
              leur taille ou leur complexité ainsi que des logiciels.
              Faites-nous confiance pour gérer votre projet de A à Z, en
              incluant la conception, le développement et la sécurité.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted" onClick={()=>window.location.href=`${process.env.REACT_APP_API_URL_SERVICES}`} style={{cursor:'pointer'}}>Obtenir un devis</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img variant="top" src="../images/coding-bro.svg" />
          <Card.Body>
            <Card.Title>Communauté développeur</Card.Title>
            <Card.Text>
              Rejoignez une communauté active de développeurs du Sénégal et
              d'Afrique.Partagez vos connaissances, collaborez sur des projets
              et grandissez ensemble dans l'univers du développement.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted" onClick={()=>window.location.href=`${process.env.REACT_APP_API_URL_COMMUNAUTY}`} style={{cursor:'pointer'}}>Découvrir</small>
          </Card.Footer>
        </Card>
      </CardGroup>
    </>
  );
};
