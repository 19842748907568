export const  CheckedFields = (field)=>{
    if(field.length>0 || field !== '')  
        return true
    else 
        return false
  }

export const  CheckedNumber = (number)=>{
    const char = /[a-bA-Z]/
    if(!char.test(parseInt(number)) && number.length >= 6 && number.length <= 14) return true
    else return  false
  }

export const CheckedMessage = (message)=>{
    if(message.length > 20 )   return true
    else return false  
}
